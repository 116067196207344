<template>
    <div>
        <Row>
          <i-col span="24">
            <Divider dashed><span class="divider-text">任务汇总</span></Divider>
            <Row class="table-title p-t-2">
                <i-col span="12" class="p-l-5 p-t-5 p-b-5">任务类型</i-col>
                <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5">数量</i-col>
            </Row>

            <Row v-for="(item,index) in taskList" :key="index" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
                <i-col span="12" class="p-l-5 p-t-5 p-b-5">{{item.key}}</i-col>
                <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5">{{showAllTask?item.taskCount:item.pendingTaskCount}}</i-col>
            </Row>

          </i-col>
        </Row>

    </div>
</template>

<script>
import { gettaskcount } from '@/api/scp/financetask'
export default {
  props: {
    showAllTask: Boolean
  },
  data () {
    return {
      taskList: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      gettaskcount().then(res => {
        if (res && !res.errcode) {
          this.taskCount = res
          this.taskList = res.taskItemCountList.map(item => {
            return { taskCount: item.taskCount, pendingTaskCount: item.pendingTaskCount, key: item.financeTaskTypeName }
          })
        }
      })
    }
  }
}
</script>
